import { storeToRefs } from 'pinia'
import type { Product, SummaryPrices } from 'checkout/types/summary'
import type { CustomLineItem, LineItem } from 'checkout/types/cart-response'

import getRevisionsText from 'checkout/utils/get-revisions-text'
import getAttribute from 'checkout/utils/get-attribute'
import type { AttributeItem, MasterVariant } from '@/types/variant'

export default () => {
  const { $__, $price, $priceObject } = useNuxtApp()

  const cartStore = useCartStore()
  const { cart } = storeToRefs(cartStore)

  const defaultPrice = $priceObject(0)
  const isCartAvailable = computed(() => !!cart.value)
  const product = ref<Product | undefined>()
  const prices = ref<SummaryPrices>({
    platformFee: '',
    extraDeliveryFee: '',
    totalPrice: '',
    subtotalPrice: '',
    postOnSocialFee: '',
  })

  const update = async () => {
    if (isCartAvailable.value) {
      const lineItem = cart?.value?.lineItems[0] as LineItem

      try {
        if (!lineItem) {
          throw new Error('No cart data found.')
        }

        const deliveryTime = lineItem?.variant?.attributes.find(
          (attribute: AttributeItem) => attribute.name === 'delivery_time',
        )?.value as string

        const isExtraDeliveryAvailable =
          lineItem?.custom?.fields?.extra_delivery || false

        const extraDeliveryTime = lineItem?.variant?.attributes?.find(
          (attribute: AttributeItem) =>
            attribute.name === 'extra_delivery_time',
        )?.value as string

        const customLineItems = cart?.value?.customLineItems as CustomLineItem[]

        const extraDeliveryFee = computed(
          () =>
            customLineItems.find(
              (price: CustomLineItem) => price.slug === 'delivery-extra-fee',
            )?.totalPrice || defaultPrice,
        )
        const serviceFee = computed(
          () =>
            customLineItems.find(
              (price: CustomLineItem) => price.slug === 'platform-fee',
            )?.totalPrice || defaultPrice,
        )

        const platforms =
          (lineItem?.variant?.attributes.find(
            (item: any) => item.name === 'extra_platforms',
          )?.value as string[]) || []

        const postOnSocialFee = defaultPrice
        const subtotalPrice = lineItem.totalPrice || defaultPrice
        const totalPrice = cart.value?.totalPrice || defaultPrice

        const numberOfRevisions = getAttribute(
          lineItem.variant.attributes,
          'revisions',
        )?.value as number

        const packageDescription = getAttribute(
          lineItem.variant.attributes,
          'variant_description',
        )?.value as string

        const licensingRights = getAttribute(
          lineItem.variant.attributes,
          'licensing_rights',
        )?.value as string

        // @todo: adjust variant types in future
        let assets = useAssets(lineItem.variant as MasterVariant)

        if (!assets.length) {
          const products = await useFetchProducts({
            expand: 'masterVariant.attributes[*].value',
            where: `slug(en-US="${$__(
              lineItem.productSlug,
            )}") and productType(id=:gig)`,
          })

          assets = useAssets(products?.results[0]?.masterVariant || [])
        }

        prices.value = {
          totalPrice: totalPrice.centAmount ? $price(totalPrice, 2) : '',
          extraDeliveryFee: extraDeliveryFee.value.centAmount
            ? $price(extraDeliveryFee.value, 2)
            : '',
          platformFee: serviceFee.value.centAmount
            ? $price(serviceFee.value, 2)
            : '',
          subtotalPrice: subtotalPrice.centAmount
            ? $price(subtotalPrice, 2)
            : '',
          postOnSocialFee: $price(postOnSocialFee, 2),
        }

        product.value = {
          id: lineItem.productId,
          imageUrl: assets?.[0]?.image || assets?.[0]?.thumbnail || '',
          imageAlt: assets?.[0]?.name?.['en-US'] || '',
          price: $price(lineItem?.price.value, 2),
          name: $__(lineItem?.name || ''),
          description: packageDescription || '',
          deliveryTime: deliveryTime || '',
          isExtraDeliveryAvailable,
          extraDeliveryTime,
          platforms,
          revisions: getRevisionsText(numberOfRevisions),
          licensingRights,
        }
      } catch (error: unknown) {
        throw createError({
          statusCode: 404,
          statusMessage:
            error instanceof Error ? error.message : 'Page not found',
          fatal: true,
        })
      }
    }
  }

  watch(cart, update, { immediate: true })

  return {
    isCartAvailable,
    product,
    prices,
  }
}
